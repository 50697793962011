.form-page {
	@apply
		relative
		h-full
}

.form-bg {
	background-size: cover;
	background-position: center;
	@apply
		absolute
		top-0
		left-0
		w-[calc(100%-0rem)] h-full
}

.form-wrapper {
	@apply
		absolute
		flex flex-col
		max-w-[22rem]
		w-full
		justify-center
		shadow-8
		left-1/2
		top-1/2
		transform -translate-x-1/2 -translate-y-1/2
}

.form-wrapper .card-title {
	@apply
		items-center
}

.form-sidebar::before {
	content: " ";
	@apply
		absolute
		top-0 left-0
		h-full w-full
		bg-background-contrast
		opacity-100

}

.form-container {
	@apply
		flex flex-col
		text-center
		p-8
		relative
		z-[5]
}

.form-container > *:not(:last-child) {
	@apply
		mb-8
}


.form-container h1 {
	@apply
		text-2xl text-center
		font-semibold
}

.form-container form {
	@apply
		flex flex-col
}

.form-container form > *:not(:last-child) {
	@apply
		mb-6
}

.form-container a {
	@apply
		text-blue-400
		hover:underline
		text-lg text-center
}

.form-container .buttons-container {
	@apply
		flex flex-col
}

.form-container .buttons-container > *:not(:last-child) {
	@apply
		mb-4
}