.theme-setting .card-title {
	@apply
		pb-4
}

.theme-preview-grid {
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	@apply
		grid
		!p-4
}

.theme-preview-grid > * {
	@apply
		m-4
}

.theme-preview {
	@apply
		cursor-pointer
		border border-divider
		rounded-card
		overflow-auto
}

.theme-preview .card-title {
	@apply
		border-t border-divider
		p-4
		flex flex-row
}

.theme-preview .card-title .radio-button-container {
	@apply
		mr-2
}

.theme-preview .card-body {
	@apply p-0
}

.theme-preview .theme-bg {
	@apply
		relative
		p-8
}

.theme-preview .theme-primary-button {
	@apply
		absolute
		h-6
		w-6
		bottom-6
		right-6
		rounded-full
}

.theme-preview .theme-card {
	@apply
		h-full
		w-full
		pt-3
}

.theme-card-header {
	@apply
		ml-3
		mb-2
}

.theme-preview .theme-list {
	@apply
		flex flex-col
		h-full
}

.theme-preview .theme-list-item {
	@apply
		relative
		flex items-center
		pl-8
		h-8
}

.theme-preview .theme-list-indicator {
	@apply
		absolute
		left-3
		top-1/2
		w-[0.625rem] h-[0.625rem]
		rounded-full
		transform -translate-y-1/2
}

.theme-preview .theme-list-item.selected:before {
	content: " ";
	background-color: var(--selected-col);
	@apply
		absolute
		w-full h-full
		left-0
		top-0
}

.theme-text {
	@apply
		h-[0.8em]
		w-[5em]
		rounded-full
}