.radio-button-container {
	@apply
		relative
		m-1
		border-2 border-action-inactive
		rounded-full
		transition-colors
		h-4
		w-4
}

.radio-button-container::before {
	content: " ";
	@apply
		absolute
		top-1/2 left-1/2
		h-6 w-6
		bg-action-hover
		rounded-full
		transform scale-0
		-translate-x-1/2 -translate-y-1/2
		transition-transform
}

.radio-button-container:hover::before {
	@apply
		scale-100
}

.radio-button-circle {
	@apply
		bg-primary-main
		rounded-full
		h-[calc(0.75rem-2px)]
		w-[calc(0.75rem-2px)]
		transition-transform
		absolute
		left-1/2 top-1/2
		-translate-x-1/2 -translate-y-1/2
		scale-0
}

.radio-button-container.selected {
	@apply
		border-primary-main
}

.radio-button-container.selected .radio-button-circle {
	@apply
		scale-100
}