.background {
	@apply
		w-full
		h-full
		absolute
		top-0
		left-0
		z-[-1]
		pointer-events-none

}

.image-center {
	@apply
		object-cover
		object-center
}

.bg-image {
	@apply
		background
		image-center
}

.bg-gradient {
	@apply
		background
}

body {
	--clip-size-x: 40px;
	--clip-size-y: 40px;
	--clip-border-width: 2px;
	--border-width: 1px;
	@apply
		h-full
}

#root {
	@apply
		h-full
}

.clip-top-left {
	clip-path: polygon(var(--clip-size-x) 0, 100% 0, 100% 100%, 0 100%, 0 var(--clip-size-y));
}

.clip-top-right {
	clip-path: polygon(0 0, calc(100% - var(--clip-size-x)) 0, 100% var(--clip-size-y), 100% 100%, 0 100%);
}

.clip-bottom-left {
	clip-path: polygon(0 0, 100% 0, 100% 100%, var(--clip-size-x) 100%, 0 calc(100% - var(--clip-size-y)));
}

.clip-bottom-right {
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--clip-size-y)), calc(100% - var(--clip-size-x)) 100%, 0 100%);
}

.clip-border-top-left {
	clip-path: polygon(var(--clip-size-x) 0%, 0% var(--clip-size-y) , 0% 100%, var(--clip-border-width) 100%, var(--clip-border-width) calc(var(--clip-border-width)/2 + var(--clip-size-y)), calc(var(--clip-border-width)/2 + var(--clip-size-x)) var(--clip-border-width), calc(100% - var(--clip-border-width)) var(--clip-border-width), calc(100% - var(--clip-border-width)) calc(100% - var(--clip-border-width)), var(--clip-border-width) calc(100% - var(--clip-border-width)), var(--clip-border-width) 100%, 100% 100%, 100% 0%)
}

.clip-border-top-right {
	clip-path: polygon(0% 0%, 0% var(--clip-size-y) , 0% 100%, var(--clip-border-width) 100%, var(--clip-border-width) var(--clip-border-width), var(--clip-border-width) var(--clip-border-width), calc(100% - var(--clip-border-width)/2 - var(--clip-size-x)) var(--clip-border-width), calc(100% - var(--clip-border-width)) calc(var(--clip-size-y) + var(--clip-border-width)/2), calc(100% - var(--clip-border-width)) calc(100% - var(--clip-border-width)), var(--clip-border-width) calc(100% - var(--clip-border-width)), var(--clip-border-width) 100%, 100% 100%, 100% var(--clip-size-y), calc(100% - var(--clip-size-x)) 0%, 100% 0%);
}

.clip-border-bottom-right {
	transform: rotateX(180deg);
	clip-path: polygon(0% 0%, 0% var(--clip-size-y) , 0% 100%, var(--clip-border-width) 100%, var(--clip-border-width) var(--clip-border-width), var(--clip-border-width) var(--clip-border-width), calc(100% - var(--clip-border-width)/2 - var(--clip-size-x)) var(--clip-border-width), calc(100% - var(--clip-border-width)) calc(var(--clip-size-y) + var(--clip-border-width)/2), calc(100% - var(--clip-border-width)) calc(100% - var(--clip-border-width)), var(--clip-border-width) calc(100% - var(--clip-border-width)), var(--clip-border-width) 100%, 100% 100%, 100% var(--clip-size-y), calc(100% - var(--clip-size-x)) 0%, 100% 0%);
}

.clip-border-bottom-left {
	transform: rotateX(180deg);
	clip-path: polygon(var(--clip-size-x) 0%, 0% var(--clip-size-y) , 0% 100%, var(--clip-border-width) 100%, var(--clip-border-width) calc(var(--clip-border-width)/2 + var(--clip-size-y)), calc(var(--clip-border-width)/2 + var(--clip-size-x)) var(--clip-border-width), calc(100% - var(--clip-border-width)) var(--clip-border-width), calc(100% - var(--clip-border-width)) calc(100% - var(--clip-border-width)), var(--clip-border-width) calc(100% - var(--clip-border-width)), var(--clip-border-width) 100%, 100% 100%, 100% 0%);
}

.short-1px {
	@apply
		left-[1px]
		top-[1px]
		h-[calc(100%-2px)]
		w-[calc(100%-2px)]
}

.short-2px {
	@apply
		left-[2px]
		top-[2px]
		h-[calc(100%-4px)]
		w-[calc(100%-4px)]
}

.divider {
	@apply
		bg-divider
		h-[1px]
		w-full
}

@media (min-width: 1600px) {
	html {
		--shf: 4.9406564584124654e-324;
		font-size: calc(1vw * var(--shf) / var(--sh))
	}
}

.loader {
	animation: loader 1000ms infinite both ease-out alternate-reverse;
	@apply
		h-[1rem]
		w-[1rem]
		bg-action-loading
		rounded-md
		inline-block
}

.loader.text {
	@apply
		h-[1em]
		w-[7em]
}

@keyframes loader {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.7;
	}
}

.private {
	@apply
		inline-block
		bg-action-redacted
		h-[1em]
		w-[6em]
		rounded-md

}

.form-group {
	@apply
		flex items-center
}