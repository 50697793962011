@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	color-scheme: dark;
}
img {
	max-width: initial;
}

html {
	font-size: 16px;
	@apply
		w-full
		h-full
		text-text-primary
}

body {
	width: 100%;
	@apply
		m-0
		p-0
		overflow-x-hidden
		bg-background-default
		font-body text-text-primary;
}

ul, li {
	@apply
		m-0
		p-0;
}

h1, h2, h3, h4, h5, h6 {
	@apply
		m-0 p-0
}

* {
	box-sizing: border-box;
}

/* *::-webkit-scrollbar {
	width: 0.75rem;
	height: 0.75rem;
}

*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
	background-color: var(--theme-background-paperDark);
} */

.thin-scroll::-webkit-scrollbar {
	width: 4px !important;
	height: 4px !important;
}

.thin-scroll::-webkit-scrollbar-track {
	box-shadow: none
}

.thin-scroll::-webkit-scrollbar-thumb {
	background-color: var(--theme-background-paperDark);
	border-radius: 1000px;
}