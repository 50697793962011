.select {
	@apply
		py-3
		items-center
		flex-row
		pr-4
		px-4
		min-h-[4rem]
		min-w-[10rem]
		cursor-pointer
}

.select.multiple {
	@apply
		pb-[0.25rem]
}

.select .dropdown-icon {
	@apply
		fill-current
		text-text-primary
		ml-auto
		flex-shrink-0
}

.select .value-container .chip {
	@apply
		relative
		mr-2
		whitespace-nowrap
}

.select .value-container .chip:not(.empty-value) {
	@apply
		pr-[2.5rem]
}

.select .value-container .chip .icon-button {
	@apply
		absolute
		top-1/2 right-1
		transform -translate-y-1/2
		fill-current
		text-text-primary
}

.select .value-container {
	overflow-x: overlay;
	@apply
		flex
		max-w-[20rem]
		overflow-x-auto
		mr-2
}

.select.multiple .value-container {
	@apply
		pb-[0.25rem]

}

.select .dropdown {
	@apply
		left-initial
		right-2
}