.alert {
	transition: bottom 300ms 200ms ease-out, left 500ms ease-out, transform 500ms ease-out, opacity 500ms ease-out;
	animation: alert-enter 500ms;
	@apply
		flex items-center
		absolute
		left-1/2
		bottom-0
		transform -translate-x-1/2
		max-w-[30rem]
		w-screen
		pl-6 pr-3 py-3
		bg-background-paper
		rounded-card
		shadow-2
		overflow-hidden
		!z-[100000];
}

@keyframes alert-enter {
	from {
		left: 0;
		transform: translate(-100%);
	}
	to {
		left: 50%;
		transform: translate(-50%);
	}
}

.alert.success {@apply bg-success-main text-success-contrastText}
.alert.success .duration-bar {@apply bg-success-dark}

.alert.info {@apply bg-info-main text-info-contrastText}
.alert.info .duration-bar {@apply bg-info-dark}

.alert.warning {@apply bg-warning-main text-warning-contrastText}
.alert.warning .duration-bar {@apply bg-warning-dark}

.alert.error {@apply bg-error-main text-error-contrastText}
.alert.error .duration-bar {@apply bg-error-dark}

.alert svg {
	@apply
		fill-current
}

.alert p {
	@apply
		font-medium
		flex-1
		mx-2
}

.alert .duration-bar {
	animation: alert-duration-bar-shrink forwards 500ms linear;
	@apply
		h-1
		w-full
		bg-primary-dark
		absolute
		bottom-0 left-0
}

@keyframes alert-duration-bar-shrink {
	from {
		width: 100%;
	}
	to {
		width: 0%;
	}
}