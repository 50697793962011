.custom-table {
	grid-template-columns: repeat(var(--table-size-columns), auto);
	@apply
		grid
		relative
		max-h-[20rem]
		overflow-auto
		pb-2
}

.custom-table-item {
	@apply
		mr-10
		-md:mr-4
}

.custom-table-item.in-last-col {
	@apply
		mr-4
}

.custom-table-item {
	@apply
		relative
		text-text-secondary
}

.custom-table-head {
	@apply
		font-bold
		text-lg
		text-text-primary
}

.custom-table-item:not(.in-last-row) {
	@apply
		mb-12
}


.custom-table-item:not(.in-last-row)::after {
	content: " ";
	@apply
		absolute
		bottom-[-1.5rem]
		left-0
		w-[calc(100%+2.5rem)]
		h-[1px]
		bg-divider
		-md:w-[calc(100%+1rem)]
}

.custom-table-item.in-last-col:not(.in-last-row)::after {
	@apply
		w-full
}