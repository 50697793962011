.input-root {
	--label-width: 0rem;
	@apply
		relative
		inline-flex flex-col
		px-5 py-4
		bg-background-paperLight
		rounded-button
		min-w-[calc(var(--label-width)+3.25rem)]
		min-h-[3rem]
}

.input-root.label-fixed::before {
	clip-path: polygon(1rem 0%,1rem 20%,calc(var(--label-width) + 1.5rem) 20%,calc(var(--label-width) + 1.5rem) 0,100% 0,100% 100%,0 100%,0 0);
}


.input-root::before {
	content: " ";
	@apply
		absolute
		top-0
		left-0
		w-full
		h-full
		border-2
		border-text-primary
		rounded-button
		pointer-events-none
		transition-colors
}

.input-root label {
	@apply
		absolute
		top-1/2
		transform -translate-y-1/2
		left-5
		pointer-events-none
		transition-all
}

.input-root.label-fixed label {
	@apply
		top-0
		text-hint
		rounded-md
}

.input-root.label-fixed::before, .input-root.label-fixed.selected::before {
	@apply border-primary-main
}

.input-root.label-fixed label, .input-root.label-fixed.selected label {
	@apply text-primary-main
}