.split-stat-root {
	@apply
		relative
}

.stat-total-label {
	@apply
		absolute
		z-[2]
		top-1/2
		transform -translate-y-1/2
}

.stat-total-label .split-value {
	@apply
		text-[1.25rem]
}

.stat-total-label .split-label-box {
	@apply
		w-[5rem] h-[5rem]
}

.stat-total-label .split-label {
	@apply
		text-[0.9rem]
}

.stat-total-label .split-label-box svg {
	@apply
		h-7 w-7
}

.split-children-container {
	@apply
		relative z-[2]
		flex flex-col
		items-end justify-between
}

.split-children-container > *:not(:last-child) {
	@apply
		mb-4
}

.split-label-container {
	@apply
		inline-flex
		items-center justify-center
}

.split-label-box {
	background-color: var(--bg-colour, var(--theme-primary-main));
	box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.5);
	@apply
		flex flex-col
		items-center justify-center
		h-[4rem] w-[4rem]
		rounded-full
}

.split-label-box svg {
	@apply
		fill-current
		mb-[0.125rem]
		h-5
		w-5
}

.split-label {
	@apply
		text-[0.65rem]
		font-bold
}

.split-value {
	color: var(--text-colour, var(--theme-primary-dark));
	@apply
		font-semibold
		mx-2
		text-[1.05rem]
		text-right
}

.split-background {
	@apply
		absolute
		top-0
		left-0
}