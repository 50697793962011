.filter-bar {
	@apply
		fixed top-0
		bg-background-default
		z-[15]
		flex
		p-4
		pt-5
		w-full
		transition-[box-shadow]
}

.scrolled .filter-bar {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.filter-bar .left {
	@apply
		flex items-center
		pl-4
}

.filters {
	@apply
		flex
		max-w-[100%]
		ml-auto
		-md:flex-wrap
		-md:mb-[-1rem]
}

.filters > *:not(:nth-last-of-type(1)) {
	@apply
		mr-8
}

.filters > * {
	@apply
		flex-shrink-[1]
		-md:mb-4
}