.percentage-stat-card {
	@apply flex flex-col
}

.percentage-stat-card .card-title {
	@apply
		items-center
}

.percentage-stat-card .card-body {
	@apply
		flex flex-row items-stretch
		justify-center
		flex-1
		-xs:flex-col
		-xs:items-center
}


.percentage-stat-card .circle-container .progress-label-container {
	@apply
		flex flex-col items-center
		absolute
		left-[5rem]
		top-[50%]
		transform -translate-x-1/2 -translate-y-1/2
}

.percentage-stat-card .progress-total {
	@apply
		text-2xl
		font-semibold
		mb-1
}

.percentage-stat-card .progress-total-label {
	@apply
		text-sm
		font-semibold
		uppercase
}

.circle-container {
	@apply
		flex flex-col items-start justify-center
		relative
		mr-[8%]
		-md:mr-0
}

.circle-container svg {
	@apply
		mr-auto
}

.percentage-stats-container {
	@apply
		flex flex-col justify-evenly
		ml-12
		pb-2
		max-w-[15rem]
		w-full
		-xs:ml-0
		-xs:mt-4
		-xs:w-auto
}

.percentage-stats-item {
	@apply
		-xs:flex
}

.percentage-stats-item .title {
	 @apply
		font-semibold relative
		-xs:mr-2
}

.percentage-stats-item .title::before {
	content: " ";
	@apply
		absolute
		top-1/2 transform -translate-y-1/2
		left-[-1.25rem]
		rounded-full
		w-[0.625rem] h-[0.625rem]
}


.percentage-stats-item.part .title::before {
	@apply
		bg-success-main
}


.percentage-stats-item.not-part .title::before {
	@apply
		bg-background-base
}

.percentage-stats-item .figure {
	@apply
		font-bold
		md:text-xl
}