.button {
	@apply
		relative
		px-4 py-3
		rounded-button
		uppercase
		font-semibold
		min-w-[8rem]
}

.button.primary {
	background: linear-gradient(to bottom right, var(--theme-primary-light) 0%, var(--theme-primary-dark) 40%, var(--theme-primary-light) 100%);
	background-position: 0 0;
	background-size: 150% 150%;
	transition: background-position 200ms ease-out;
	@apply
		text-primary-contrastText
		border-primary-main
}

.button.primary:hover {
	background-position: 50% 50%;
}

.button.default {
	@apply
		bg-background-contrast
		rounded-none
		hover:bg-action-hover
		transition-colors
}

.button.outlined {
	background: none;
	@apply
		text-text-primary
		border-2
}

.button.contained:disabled {
	background-image: none;
	@apply
		bg-action-disabled
}

.button.outlined:disabled {
	@apply
		border-action-disabled
		text-action-disabled
}

.button.loading {
	@apply
		!text-transparent
}

.button.loading .spinner-container {
	@apply
		absolute
		left-1/2 top-1/2
		transform -translate-x-1/2 -translate-y-1/2
}

.button.primary.contained.loading .spinner-container svg {
	@apply
		fill-current
		text-primary-contrastText
}

.button.outlined.loading .spinner-container svg {
	@apply
		fill-current
		text-text-primary
}

.button.default.contained.loading .spinner-container svg {
	@apply
		text-text-primary
		fill-current
}