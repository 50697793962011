.circular-progress {
	@apply
		flex items-center justify-center
}

.circular-progress .filled {
	transform: rotateZ(-90deg) rotateY(-180deg);
	@apply
		stroke-current
		text-primary-main
}

.circular-progress .filled {
	stroke-linecap: round;
	@apply
		stroke-current
		text-primary-main
}

.circular-progress .track {
	@apply
		stroke-current
		text-background-base
}