.tooltip {
	@apply
		bg-background-paper
		border-border-main
		relative
		shadow-8
}

.recharts-tooltip-wrapper {
	@apply
		z-[1000]
}

.tooltip-details {
	@apply
		py-2
		px-4
}

.tooltip-label {
	@apply
		font-bold
		mb-1
}

.tooltip-item {
	@apply
		my-1
}

.tooltip-item-separator {
	@apply
		ml-[0.125rem]
		mr-1
		font-bold
}

.tooltip-list.grid {
	display: grid;
	grid-template-columns: auto auto;
}

.tooltip-list.grid {
	@apply
		mr-[-0.5rem]
}

.tooltip-list.grid .tooltip-item {
	@apply
		mr-3
		inline-flex items-center
}