.card-filtered {
	@apply flex flex-col
}

.card-filtered .card-body {
	@apply
		flex flex-1
		-md:flex-col-reverse
}

.filtered-data-container {
	@apply
		flex flex-col
		flex-[3]
		min-w-[40%]
}

.card-filtered .filtered-list {
	@apply
		flex flex-col
		flex-1
		flex-shrink-0
}

.filtered-list-item {
	@apply
		px-1
		py-2
		rounded-sm
		transition-colors
}

*:not(.skeleton) > .filtered-list-item {
	@apply
		hover:bg-action-hover
		cursor-pointer
}

.filtered-list-item.active {
	@apply
		bg-action-hover
}

.filtered-list-item.selected {
	@apply
		!bg-background-paperLight
}

.filtered-list-item .label {
	@apply
		font-semibold
}

.filtered-bar-container {
	@apply
		flex items-center
}

.filtered-bar {
	@apply
		h-2
		rounded-full
		inline-block
		align-middle
		transition-width
		min-w-[0.25rem]
}

.filtered-bar-label {
	@apply
		inline-block
		align-middle
		ml-2
		mt-[0.125rem]
}

.pagination-buttons {
	@apply
		flex justify-evenly items-center
		mt-4
}

.filtered-hover-data {
	@apply
		relative
		flex flex-col justify-between
		py-8
		flex-[2]
		ml-12
		my-auto
		text-center
		max-h-full
		h-[20rem]
		min-w-[20rem]

		-md:mb-0
		-md:mt-0
		-md:ml-0
		-md:max-h-initial
		-md:flex-none
		-md:h-[15rem]
		-md:pt-0
}

.filtered-hover-data::before {
	content: " ";
	@apply
		absolute
		left-[-1rem]
		top-0
		transform
		h-full
		w-[1px]
		bg-divider
		-md:hidden
}

.filtered-hover-data .filtered-title-container {
	@apply
		flex flex-col items-center
}

.filtered-hover-data .filtered-title-container::before {
	content: " ";
	@apply
		absolute
		top-1/2 left-1/2
		transform -translate-x-1/2
		w-[80%]
		h-[1px]
		bg-divider
}

.filtered-hover-data .filtered-title {
	@apply
		text-xl
		font-bold
		mb-6
}

.filtered-hover-data .filtered-title-percent {
	@apply
		text-2xl
}


.filtered-subtitle {
	@apply
		mb-16
		text-2xl
}

.filtered-hover-list {
	@apply
		flex
		justify-evenly
}

.filtered-hover-list-item {
	@apply
		flex flex-col items-center
		text-lg
		whitespace-nowrap
		mx-1
}

.filtered-hover-list-item {
	@apply
		font-semibold
}

.filtered-hover-list-item .title {
	@apply
		text-base
		-sm:text-sm
}

.filtered-hover-list-item .value {
	@apply
		font-semibold
		text-xl
		mt-2
		-sm:text-base
}